import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import Layout from '../../../components/Layout';
import {
  NavigationParamList,
  ScreenName,
  TabNavigationScreenProps,
} from '../../../navigation/types';
import { useAuth } from '../../../contexts/AuthContext/AuthContext';
import {
  AdminArticleRead,
  AdminPostOrderBy,
  GetAdminArticlesParams,
  Order,
  Pagination as IPagination,
  Role,
} from '../../../client/interfaces';
import { AdminPostFilter, ArticleTable, Button, Pagination } from '../../../components';
import client from '../../../client/client';
import { useMessage } from '../../../contexts/Messages/MessageContext';

export const emptyGetAdminArticlesParams: GetAdminArticlesParams = {
  orderBy: AdminPostOrderBy.createdAtUtc,
  order: Order.DESC,
  search: '',
  page: 1,
  status: undefined,
  tags: [],
};

const AdminArticleListScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.AdminArticleListScreen>
> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { me, hasRole } = useAuth();
  const { setMessage } = useMessage();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [getAdminArticlesParams, setGetAdminArticlesParams] = useState<GetAdminArticlesParams>({
    ...emptyGetAdminArticlesParams,
    ...route.params,
  });
  const [adminArticles, setAdminArticles] = useState<AdminArticleRead[]>([]);
  const [pagination, setPagination] = useState<IPagination>();

  const getAdminArticlesParamsJSON = JSON.stringify(getAdminArticlesParams);

  // Role guard
  useFocusEffect(
    useCallback(() => {
      if (me && !hasRole(Role.publisher)) {
        navigation.navigate(ScreenName.HomeScreen);
      } else {
        setGetAdminArticlesParams(emptyGetAdminArticlesParams);
      }
    }, [me?.roleList]),
  );

  const fetchAdminArticles = async () => {
    setIsLoading(true);

    try {
      const response = await client.getAdminArticles(getAdminArticlesParams);
      setAdminArticles(response.items);
      setPagination(response.pagination);
    } catch (e) {
      console.error(e);
      setMessage({ message: e.message, type: 'error' });
    }

    setIsLoading(false);
  };

  useFocusEffect(
    useCallback(() => {
      fetchAdminArticles();
    }, [getAdminArticlesParamsJSON]),
  );

  useEffect(() => {
    setGetAdminArticlesParams((prev) => ({ ...prev, ...route.params }));
  }, [route.params]);

  return (
    <Layout title={t('admin:article_list:title')} titleMarginBottom={false}>
      <Button
        text={t('admin:article_list:add_new')}
        variant={'fill'}
        onPress={() => navigation.navigate(ScreenName.AdminArticleEditScreen)}
        size={'medium'}
        style={{ alignItems: 'flex-end', marginBottom: 40 }}
      />
      <AdminPostFilter
        params={getAdminArticlesParams}
        paramSetter={setGetAdminArticlesParams}
        pagination={pagination}
      />
      <ArticleTable
        rows={adminArticles}
        paramSetter={setGetAdminArticlesParams}
        params={getAdminArticlesParams}
        refresh={fetchAdminArticles}
        isLoading={isLoading}
      />
      {pagination && (
        <Pagination
          data={pagination}
          paramSetter={setGetAdminArticlesParams}
          params={getAdminArticlesParams}
        />
      )}
    </Layout>
  );
};

export default AdminArticleListScreen;
