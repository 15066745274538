import React, { useCallback, useState } from 'react';
import { Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect, useIsFocused } from '@react-navigation/core';
import { ErrorMessage, Field, Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty, values as _values } from 'lodash';

import Layout from '../../../components/Layout';
import {
  NavigationParamList,
  ScreenName,
  TabNavigationScreenProps,
} from '../../../navigation/types';
import { useAuth } from '../../../contexts/AuthContext/AuthContext';
import {
  AdminArticleCreate,
  AdminPostReadDetail,
  ArticleTranslationRole,
  FileModuleType,
  PersonaType,
  Role,
  Translation,
} from '../../../client/interfaces';
import {
  Button,
  FileUpload,
  FormErrorNotification,
  FormInput,
  Modal,
  PersonaCheckboxGroup,
  TagSelector,
} from '../../../components';
import { useAdmin } from '../../../contexts/Admin/AdminContext';
import { useMessage } from '../../../contexts/Messages/MessageContext';
import client from '../../../client/client';
import { getTranslation } from '../../../utils/translations';
import {
  DeepPartial,
  FileUploadStatus,
  Language,
  UploadedFile,
} from '../../../resources/interfaces';
import { readFileUrl } from '../../../utils/file';
import { extensionImageWhitelist } from '../../../components/FileUpload/helpers';

const MAX_FILE_SIZE = 1000 * 1000; // 1 MB

type ArticleSchemaTranslations = {
  [key in Language]: { [key in ArticleTranslationRole]: string };
};

/* eslint-disable camelcase */
interface ArticleSchema {
  translations: ArticleSchemaTranslations;
  tagIds: number[];
  personaKeys: PersonaType[];
  published: boolean;
  fileId: number;
  files?: { [k: string]: UploadedFile };
}
const emptyFormValues: ArticleSchema = {
  translations: {
    hu: {
      title: '',
      description: '',
      cta_label: '',
      cta_link: '',
    },
    en: {
      title: '',
      description: '',
      cta_label: '',
      cta_link: '',
    },
  },
  tagIds: [],
  personaKeys: [],
  published: false,
  fileId: null,
  files: undefined,
};

const AdminArticleEditScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.AdminArticleEditScreen>
> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { me, hasRole } = useAuth();
  const { isBackModalOpen, setIsBackModalOpen } = useAdmin();
  const isFocused = useIsFocused();

  const [initialFormValues, setInitialFormValues] = useState<ArticleSchema>(emptyFormValues);
  const { setMessage } = useMessage();

  const isNewArticle = !route?.params?.postId;
  const requiredTranslation = t('admin:common:required_field');
  const tooLongTranslation = (max) => t('admin:common:max_characters', { max });

  const requiredTogetherValidation = (otherFields, schema) => {
    const anyOtherFieldHasValue = otherFields.length && otherFields.some((field) => !!field);

    if (anyOtherFieldHasValue) {
      return schema.required(requiredTranslation);
    }

    return schema;
  };

  const articleSchema = Yup.lazy((): Yup.ObjectSchema<DeepPartial<ArticleSchema>> => {
    return Yup.object().shape(
      {
        fileId: Yup.number(),
        files: Yup.object()
          .test('object-is-not-empty', requiredTranslation, (value) => !isEmpty(value))
          .test(
            'upload-status-is-done',
            requiredTranslation,
            (value) => _values(value)[0] && _values(value)[0].status === FileUploadStatus.done,
          ),
        translations: Yup.object().shape({
          hu: Yup.object().shape({
            title: Yup.string().required(requiredTranslation).max(100, tooLongTranslation(100)),
            description: Yup.string()
              .required(requiredTranslation)
              .max(5000, tooLongTranslation(5000)),
            cta_label: Yup.string()
              .when(
                [
                  '$translations.en.cta_label',
                  '$translations.hu.cta_link',
                  '$translations.en.cta_link',
                ],
                requiredTogetherValidation,
              )
              .max(40, tooLongTranslation(40)),
            cta_link: Yup.string()
              .url(t('error_messages:url_format'))
              .when(
                [
                  '$translations.en.cta_link',
                  '$translations.hu.cta_label',
                  '$translations.en.cta_label',
                ],
                requiredTogetherValidation,
              ),
          }),
          en: Yup.object().shape({
            title: Yup.string().required(requiredTranslation).max(100, tooLongTranslation(100)),
            description: Yup.string()
              .required(requiredTranslation)
              .max(5000, tooLongTranslation(5000)),
            cta_label: Yup.string()
              .when(
                [
                  '$translations.hu.cta_label',
                  '$translations.en.cta_link',
                  '$translations.hu.cta_link',
                ],
                requiredTogetherValidation,
              )
              .max(40, tooLongTranslation(40)),
            cta_link: Yup.string()
              .url(t('error_messages:url_format'))
              .when(
                [
                  '$translations.hu.cta_link',
                  '$translations.en.cta_label',
                  '$translations.hu.cta_label',
                ],
                requiredTogetherValidation,
              ),
          }),
        }),
        tagIds: Yup.array().min(1, requiredTranslation),
        personaKeys: Yup.array().min(1, requiredTranslation),
        published: Yup.boolean(),
      },
      [
        ['translations.en.cta_label', 'translations.hu.cta_label'],
        ['translations.en.cta_link', 'translations.hu.cta_link'],
      ],
    );
  });

  const transformArticleToSchema = async (article: AdminPostReadDetail): Promise<ArticleSchema> => {
    let file: File;

    if (article.fileId) {
      file = await readFileUrl(article.imageUrl);
    }

    return {
      translations: {
        hu: {
          title: getTranslation(article.allTranslations, ArticleTranslationRole.title, Language.hu),
          description: getTranslation(
            article.allTranslations,
            ArticleTranslationRole.description,
            Language.hu,
          ),
          cta_label: getTranslation(
            article.allTranslations,
            ArticleTranslationRole.ctaLabel,
            Language.hu,
          ),
          cta_link: getTranslation(
            article.allTranslations,
            ArticleTranslationRole.ctaLink,
            Language.hu,
          ),
        },
        en: {
          title: getTranslation(article.allTranslations, ArticleTranslationRole.title, Language.en),
          description: getTranslation(
            article.allTranslations,
            ArticleTranslationRole.description,
            Language.en,
          ),
          cta_label: getTranslation(
            article.allTranslations,
            ArticleTranslationRole.ctaLabel,
            Language.en,
          ),
          cta_link: getTranslation(
            article.allTranslations,
            ArticleTranslationRole.ctaLink,
            Language.en,
          ),
        },
      },
      tagIds: article.tags.map((tag) => tag.id),
      personaKeys: article.personas.map((persona) => persona.key),
      published: !!article.publishedAtUtc,
      fileId: article.fileId,
      files: file
        ? {
            [file.name]: {
              id: article.fileId,
              status: FileUploadStatus.done,
              src: article.imageUrl,
              file,
            },
          }
        : {},
    };
  };

  const transformSchemaToArticle = (values: ArticleSchema): AdminArticleCreate => {
    const { translations: translationValues, tagIds, published, fileId, personaKeys } = values;

    const translations = Object.entries(translationValues).flatMap((v) => {
      const [lang, trans] = v;
      return Object.entries(trans)
        .map((t) => {
          const [key, value] = t;
          return { language: lang, value, role: key };
        })
        .filter((t) => !!t.value);
    });

    return {
      translations: translations as Translation<ArticleTranslationRole>[],
      tagIds,
      personaKeys,
      published,
      fileId,
    };
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const article = transformSchemaToArticle(values);

      if (isNewArticle) {
        await client.createArticle(article);
      } else {
        await client.updateArticle(route.params.postId, article);
      }

      setSubmitting(false);
      resetForm({ values: emptyFormValues });
      navigation.navigate(ScreenName.AdminArticleListScreen);
    } catch (e) {
      setMessage({ message: e.message, type: 'error' });
    }
  };

  // Role guard
  useFocusEffect(
    useCallback(() => {
      if (me && !hasRole(Role.publisher)) {
        navigation.navigate(ScreenName.HomeScreen);
      }
    }, [me?.roleList]),
  );

  useFocusEffect(
    useCallback(() => {
      window.scrollTo({ top: 0, behavior: 'instant' });

      const fetchArticle = async () => {
        try {
          const articleRes = await client.getAdminArticle(route.params.postId);
          const transformed = await transformArticleToSchema(articleRes);
          setInitialFormValues(transformed);
        } catch (e) {
          setMessage({ message: e.message, type: 'error' });
          navigation.navigate(ScreenName.AdminArticleListScreen);
        }
      };

      if (route?.params?.postId) {
        fetchArticle();
      } else {
        setInitialFormValues(emptyFormValues);
      }
    }, [route?.params?.postId]),
  );

  return (
    <Layout
      title={
        isNewArticle ? t('admin:article_edit:create_title') : t('admin:article_edit:edit_title')
      }
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={articleSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          isSubmitting,
          resetForm,
          validateForm,
          dirty,
        }) => (
          <View className="flex-1">
            <FormErrorNotification />
            <Field name="files">
              {({ field, meta }) => (
                <View className="mb-11">
                  <View className="flex-row mb-1">
                    <Text className="text-input text-neutral-950 dark:text-neutral-50 font-[sans-600]">
                      {t('admin:common:cover_image')}
                    </Text>
                    <Text className="text-input text-danger-500 font-[sans-400]"> *</Text>
                  </View>
                  <FileUpload
                    files={field.value}
                    setValue={(value) => {
                      setFieldValue(field.name, value);

                      const id = Object.values(value)[0]?.id;

                      if (id) {
                        setFieldValue('fileId', id);
                      }
                    }}
                    module={FileModuleType.feed}
                    hasError={!!(meta.touched && meta.error)}
                    accepted={extensionImageWhitelist}
                    maxSize={MAX_FILE_SIZE}
                  />
                  <ErrorMessage name={field.name}>
                    {(message) => (
                      <Text className="text-notificationMessage text-danger-500 font-[sans-600] absolute bottom-[-20px]">
                        {message}
                      </Text>
                    )}
                  </ErrorMessage>
                </View>
              )}
            </Field>
            <View className="flex-1 flex-row gap-x-20 mb-10 z-30">
              <View className="flex-1">
                <FormInput
                  name="translations.hu.title"
                  title={t('admin:article_edit:title')}
                  placeholder={t('admin:common:max_characters', { max: 100 })}
                  required
                />
                <FormInput
                  name="translations.hu.description"
                  title={t('admin:common:description')}
                  placeholder={t('admin:common:max_characters', { max: 5000 })}
                  required
                  multiline={true}
                  numberOfLines={5}
                />
                <FormInput
                  name="translations.hu.cta_label"
                  title={t('admin:common.cta_label')}
                  placeholder={t('admin:common:max_characters', { max: 40 })}
                  required={[
                    values?.translations?.hu?.cta_label,
                    values?.translations?.hu?.cta_link,
                    values?.translations?.en?.cta_label,
                    values?.translations?.en?.cta_link,
                  ].some((v) => !!v)}
                />
                <FormInput
                  name="translations.hu.cta_link"
                  title={t('admin:common.cta_link')}
                  placeholder={t('admin:common.cta_link_placeholder')}
                  required={[
                    values?.translations?.hu?.cta_label,
                    values?.translations?.hu?.cta_link,
                    values?.translations?.en?.cta_label,
                    values?.translations?.en?.cta_link,
                  ].some((v) => !!v)}
                />
                <Field name="tagIds">
                  {({ field, meta }) => (
                    <View className="mb-11 z-30">
                      <View className="flex-row">
                        <Text className="text-input text-neutral-950 dark:text-neutral-50 font-[sans-600]">
                          {t('admin:common:tags')}
                        </Text>
                        <Text className="text-input text-danger-500 font-[sans-400]"> *</Text>
                      </View>
                      <TagSelector
                        tagParams={values.tagIds}
                        setTagParams={(t) => setFieldValue(field.name, t)}
                        hasError={!!meta.touched && !!meta.error}
                      />
                      <ErrorMessage name={field.name}>
                        {(message) => (
                          <Text className="text-notificationMessage text-danger-500 font-[sans-600] absolute top-[76px]">
                            {message}
                          </Text>
                        )}
                      </ErrorMessage>
                    </View>
                  )}
                </Field>
                <PersonaCheckboxGroup required={true} />
              </View>
              <View className="flex-1">
                <FormInput
                  name="translations.en.title"
                  title={t('admin:article_edit:title_en')}
                  placeholder={t('admin:common:max_characters', { max: 100 })}
                  required
                />
                <FormInput
                  name="translations.en.description"
                  title={t('admin:common:description_en')}
                  placeholder={t('admin:common:max_characters', { max: 5000 })}
                  required
                  multiline={true}
                  numberOfLines={5}
                />
                <FormInput
                  name="translations.en.cta_label"
                  title={t('admin:common.cta_label_en')}
                  placeholder={t('admin:common:max_characters', { max: 40 })}
                  required={[
                    values?.translations?.hu?.cta_label,
                    values?.translations?.hu?.cta_link,
                    values?.translations?.en?.cta_label,
                    values?.translations?.en?.cta_link,
                  ].some((v) => !!v)}
                />
                <FormInput
                  name="translations.en.cta_link"
                  title={t('admin:common.cta_link_en')}
                  placeholder={t('admin:common.cta_link_placeholder')}
                  required={[
                    values?.translations?.hu?.cta_label,
                    values?.translations?.hu?.cta_link,
                    values?.translations?.en?.cta_label,
                    values?.translations?.en?.cta_link,
                  ].some((v) => !!v)}
                />
              </View>
            </View>
            <View className="self-end flex-row gap-x-6">
              <Button
                text={t('admin:common:exit_from_edit')}
                size="medium"
                variant="link"
                onPress={() => {
                  setIsBackModalOpen(true);
                }}
                disabled={isSubmitting}
              />
              {!values.published && (
                <Button
                  text={t('admin:common:save_as_draft')}
                  size="medium"
                  variant="outline"
                  onPress={async () => {
                    await setFieldValue('published', false);
                    handleSubmit();
                  }}
                  disabled={isSubmitting || !dirty}
                />
              )}
              <Button
                text={values.published ? t('admin:common:save_changes') : t('admin:common:publish')}
                size="medium"
                variant="fill"
                onPress={async () => {
                  const validationErrors = await validateForm(values);
                  isEmpty(validationErrors) && (await setFieldValue('published', true));
                  handleSubmit();
                }}
                disabled={isSubmitting || (!dirty && (isNewArticle || values.published))}
              />
            </View>
            <Modal
              showModal={isBackModalOpen && isFocused}
              title={t('admin:common:exit_warning')}
              callback={() => {
                setIsBackModalOpen(false);
                resetForm({ values: emptyFormValues });
                navigation.navigate(ScreenName.AdminArticleListScreen);
              }}
              closeModal={() => {
                setIsBackModalOpen(false);
              }}
              yesText={t('admin:common:exit')}
              cancelText={t('admin:common:stay')}
              instantCallback={!dirty}
            />
          </View>
        )}
      </Formik>
    </Layout>
  );
};

export default AdminArticleEditScreen;
