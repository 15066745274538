import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';

import { Layout, Loader } from '../../components';
import {
  default as SectionListWeb,
  default as SectionListNative,
} from '../../components/SectionList/index';
import { NavigationParamList, ScreenName, TabNavigationScreenProps } from '../../navigation/types';
import { isNative } from '../../utils/responsive';

const HomeScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.HomeScreen>
> = ({ navigation }) => {
  const [isLoading, setIsLoading] = useState(true);

  // TODO: csúnya megoldás a navigálás utáni scrollToTop-ra, illetve, hogy működjön az azonos oldalra navigáláskor is. screenListeners-el meg lehet oldani web-re, natívra még nem sikerült jó megoldást találni
  useEffect(() => {
    return navigation.addListener('state', (a) => {
      setIsLoading(true);
      if (navigation.isFocused()) {
        setTimeout(() => setIsLoading(false), isNative ? 100 : 300);
      }
    });
  }, [navigation]);

  return (
    <Layout isScrollView={!isNative} isHomeScreen={true}>
      {isLoading ? (
        <View className="flex-1 items-center justify-center">
          <Loader />
        </View>
      ) : Platform.OS === 'web' ? (
        <SectionListWeb />
      ) : (
        <SectionListNative />
      )}
    </Layout>
  );
};
export default HomeScreen;
