import React, { useCallback } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import { ExtremeEventSection, Section } from '../../components/index';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import { useExtremeEvents } from '../../contexts/ExtremeEvent/ExtremeEventContext';
import { isMobile, isMobileWeb } from '../../utils/responsive';
import { useModules } from '../../contexts/Module/ModuleContext';

const SectionList: React.FC = () => {
  const { extremeEvents, fetchExtremeEvents } = useExtremeEvents();
  const { modules, modulesToRoles } = useModules();

  useFocusEffect(
    useCallback(() => {
      fetchExtremeEvents();
    }, []),
  );

  const { t } = useTranslation();
  const { me } = useAuth();

  const renderedElementsWeb = modules.map((section, itemIndex) => {
    const ChildComponent = section.component;
    const roles = me?.roleList;
    let showAddNew = false;

    if (roles && roles.length > 0) {
      showAddNew = roles.some((role) => role === modulesToRoles[section.key]);
    }

    return (
      ChildComponent && (
        <Section key={section.key} title={t(`section:${section.key}`)} showAddNew={showAddNew}>
          <ChildComponent />
        </Section>
      )
    );
  });

  return (
    <View>
      {extremeEvents && (
        <ExtremeEventSection
          containerStyle={{
            marginTop: isMobileWeb ? 40 : 0,
            marginBottom:
              extremeEvents.length > 0 && isMobile
                ? 40
                : extremeEvents.length > 0 && !isMobile
                  ? 80
                  : 0,
          }}
        />
      )}
      <div
        style={{
          gap: isMobileWeb ? 40 : 80,
          display: 'flex',
          flexDirection: 'column',
          marginTop: 0,
        }}
      >
        {renderedElementsWeb}
      </div>
    </View>
  );
};
export default SectionList;
