import React from 'react';
import { View, Text, Pressable } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useColorScheme } from 'nativewind';
import Layout from '../../components/Layout/index';
import navigationConfig from '../../navigation/navigationConfig';
import {
  ScreenName,
  NavigationParamList,
  Navigator,
  TabNavigationScreenProps,
} from '../../navigation/types';
import svgIcons from '../../assets';
import { Icon } from '../../components';
import { useAuth } from '../../contexts/AuthContext/AuthContext';

const ProfileScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.ProfileScreen>
> = ({ navigation }) => {
  const { t } = useTranslation();
  const { colorScheme } = useColorScheme();
  const { logout } = useAuth();

  return (
    <Layout title={t('navigation:profile')}>
      {navigationConfig.map((navItem) => {
        const navigationDestination = navItem.name.split('/')[1];

        return (
          navItem.name.startsWith(Navigator.profile) &&
          !navItem.name.toLowerCase().includes('admin') && (
            <View key={navItem.name}>
              <Pressable
                onPress={() => navigation.navigate(ScreenName[navigationDestination])}
                style={{
                  borderBottomWidth: 1,
                  borderColor: colorScheme === 'dark' ? '#525252' : '#E7E7E7',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingVertical: 14,
                }}
              >
                <Text
                  style={{
                    color: colorScheme === 'dark' ? '#f6f6f6' : '#101010',
                    fontSize: 16,
                    fontFamily: 'sans-600',
                    fontWeight: '600',
                  }}
                >
                  {t(navItem.translation)}
                </Text>
                <Icon
                  icon={svgIcons.arrowRightIcon}
                  mobileSize={{ width: 34, height: 34 }}
                  webSize={{ width: 20, height: 20 }}
                />
              </Pressable>
            </View>
          )
        );
      })}
      <Pressable
        onPress={() => logout()}
        style={{
          borderBottomWidth: 1,
          borderColor: colorScheme === 'dark' ? '#525252' : '#E7E7E7',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingVertical: 10,
          height: 60,
        }}
      >
        <Text
          style={{
            color: colorScheme === 'dark' ? '#f6f6f6' : '#101010',
            fontSize: 16,
            fontFamily: 'sans-600',
            fontWeight: '600',
          }}
        >
          {t('common:logout')}
        </Text>
      </Pressable>
    </Layout>
  );
};

export default ProfileScreen;
