import { Dayjs } from 'dayjs';
import { FC } from 'react';
import { Language } from '../resources/interfaces';

export enum AppEnv {
  dev = 'dev',
  prod = 'prod',
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  displayName: string;
  neptunCode: string;
  createdAtUtc: string;
  deletedAtUtc?: string;
  updatedAtUtc?: string;
  email: string;
  conditionAccepted: boolean;
}

export interface CookieOptions {
  days?: number;
  path?: string;
  domain?: string;
  SameSite?: 'None' | 'Lax' | 'Strict';
  Secure?: boolean;
  HttpOnly?: boolean;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  user: User;
  isDelegated: boolean;
}

export interface OnboardedUser {
  userId: number;
  languageId: number;
  createdAtUtc: string;
  updatedAtUtc: string;
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum PostOrderBy {
  publishedAtUtc = 'published_at_utc',
  startDate = 'start_date',
}

export interface OrderParams {
  orderBy?: PostOrderBy;
  order?: Order;
}

export enum AdminPostOrderBy {
  createdAtUtc = 'created_at_utc',
  startDate = 'start_date',
  endDate = 'end_date',
  title = 'title',
}

export interface AdminOrderParams {
  orderBy?: AdminPostOrderBy;
  order?: Order;
}

export interface PaginationParams {
  page?: number;
  perPage?: number;
}

export interface Pagination {
  total: number;
  perPage: number;
  currentPage: number;
  pageCount: number;
  fromNumber: number;
  toNumber: number;
}

export interface PaginatedResponse<T> {
  items: T[];
  pagination: Pagination;
}

export enum PostType {
  article = 'article',
  event = 'event',
  extremeEvent = 'extreme_event',
}

export enum ArticleTranslationRole {
  title = 'title',
  description = 'description',
  ctaLabel = 'cta_label',
  ctaLink = 'cta_link',
}

export enum EventTranslationRole {
  title = 'title',
  description = 'description',
  ctaLabel = 'cta_label',
  ctaLink = 'cta_link',
}

export enum ExtremeEventTranslationRole {
  title = 'title',
  description = 'description',
}

export enum CalendarEventTranslationRole {
  title = 'title',
}

export type AnyTranslationRole =
  | ArticleTranslationRole
  | EventTranslationRole
  | ExtremeEventTranslationRole
  | CalendarEventTranslationRole;

export interface Translation<T extends AnyTranslationRole> {
  value: string;
  role: T;
  language: Language;
}

export interface Article {
  id: number; // FIXME: Ez egyelőre a post id, nem az article id
  type: PostType.article;
  translations: Translation<ArticleTranslationRole>[];
  publishedAtUtc: string;
  isBookmarked?: boolean;
  imageUrl?: string;
}
export interface Event {
  id: number;
  type: PostType.event;
  translations: Translation<EventTranslationRole>[];
  publishedAtUtc: string;
  isBookmarked?: boolean;
  imageUrl?: string;
  relatedItem: EventRelatedItem;
}

export interface EventRelatedItem {
  id: number;
  location: string;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  joinLink?: string;
}

export interface ExtremeEvent {
  id: number;
  type: PostType.extremeEvent;
  translations: Translation<ExtremeEventTranslationRole>[];
  publishedAtUtc: string;
  relatedItem: ExtremeEventRelatedItem;
  imageUrl?: string;
}

export interface ExtremeEventRelatedItem {
  id: number;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
}

export interface CalendarEvent {
  name: string;
  location: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  joinLink: string; // TODO: ez nem opcionális?
  shouldShowNow?: boolean;
  shouldShowNowLine?: boolean;
  next?: boolean;
  startDateTime?: Dayjs;
  endDateTime?: Dayjs;
}

export type EventCardItem = Event | Article;
export type CalendarCardItem = CalendarEvent;

export interface GetArticlesParams extends OrderParams, PaginationParams {
  search?: string;
  tags?: string[];
  isBookmarked?: boolean;
}

export interface GetAdminArticlesParams extends AdminOrderParams, PaginationParams {
  search?: string;
  tags?: string[];
  status?: PostStatus;
}

export interface GetAdminExtremeEventsParams extends AdminOrderParams, PaginationParams {
  search?: string;
  tags?: string[];
  status?: PostStatus;
}

export interface GetAdminEventsParams extends AdminOrderParams, PaginationParams {
  search?: string;
  tags?: string[];
  status?: PostStatus;
}

export enum PostStatus {
  published = 'published',
  draft = 'draft',
}

export enum PostStatusFilter {
  published = 'published',
  draft = 'draft',
  all = 'all',
}

export interface GetEventsParams extends OrderParams, PaginationParams {
  search?: string;
  tags?: string[];
  isBookmarked: boolean;
  isPast?: boolean;
  date?: string;
}

export interface GetSimilarArticlesParams {
  postId: number;
  tagIds: number[];
  limit: number;
}

export interface GetSimilarEventsParams {
  postId: number;
  tagIds: number[];
  limit: number;
}

export interface Tag {
  id: number;
  key: string;
  translationValue: string;
  parentId: number;
  shouldNotify?: boolean;
  isRequired: boolean;
}

export interface PostTag {
  createdAtUtc: string;
  deletedAtUtc?: string;
  id: number;
  key: string;
  parentId?: number;
  updatedAtUtc: string;
}

export interface ArticleResponse {
  id: number;
  publishedAtUtc: string;
  publisher: string;
  tags: PostTag[];
  translations: Translation<ArticleTranslationRole>[];
  type: PostType.article;
}

export interface EventResponse {
  id: number;
  publishedAtUtc: string;
  publisher: string;
  tags: PostTag[];
  translations: Translation<EventTranslationRole>[];
  type: PostType.event;
}

export interface UserTag {
  id: number;
  translationValue: string;
  parentId: number;
  shouldNotify: boolean;
}

export interface TagLink {
  tagId: number;
  shouldNotify: boolean;
}

export interface TagLinkList {
  tagLinks: TagLink[];
}

export interface TagIdList {
  ids: number[];
}

export interface NotificationIds {
  ids: number[];
}

export interface Notification {
  imageUrl: string;
  createdAtUtc: string;
  id: number;
  postId: number;
  seenAtUtc?: string;
  titleTranslation: string;
  timeAgo?: string;
  type: PostType;
}

export interface Module {
  id: number;
  key: ModuleKey;
  component?: FC;
}

export enum ModuleKey {
  feed = 'feed',
  event = 'event',
  today = 'today',
}

export type ModulesToRoles = {
  [key in ModuleKey]: Role | null;
};

export interface PostModuleOrdersParams {
  moduleIds: number[];
}

export enum Role {
  superAdmin = 'super_admin',
  publisher = 'publisher',
  extremeEventAdmin = 'extreme_event_admin',
}

export interface MeResponse {
  isOnboarded: boolean;
  roleList: Role[];
  pushTokens: string[];
  email: string;
  profilePhoto: string;
  language?: Language;
  personas: PersonaDetail[];
}

export interface PushNotificationPost {
  type: PostType;
  id: number;
}

export interface PushNotificationData {
  post: PushNotificationPost;
}

export interface AdminPostReadBase {
  id: number;
  titleTranslation: string;
  createdAtUtc: string;
  publishedAtUtc: string;
  lastEditedBy?: string;
}

export interface AdminPostRead extends AdminPostReadBase {
  tags: PostTag[];
  personas: Persona[];
  article?: AdminArticleRead;
  event?: AdminEventRead;
  extremeEvent?: AdminExtremeEventRead;
}

export interface AdminPostReadDetail extends AdminPostReadBase {
  id: number;
  article?: AdminArticleRead;
  event?: AdminEventRead;
  extremeEvent?: AdminExtremeEventRead;
  allTranslations: Translation<ArticleTranslationRole>[];
  tags: PostTag[];
  personas: Persona[];
  createdAtUtc: string;
  publishedAtUtc: string;
  fileId?: number;
  imageUrl?: string;
}

export interface AdminArticleRead {
  id: number;
}

export interface AdminExtremeEventRead {
  id: number;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  displayFromDate: string;
  displayFromTime: string;
}

export interface AdminEventRead {
  id: number;
  location: string;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  joinLink?: string;
  isEnglish: boolean;
}

export interface AdminArticleCreate {
  translations: Translation<ArticleTranslationRole>[];
  tagIds: number[];
  personaKeys: PersonaType[];
  published: boolean;
  fileId: number;
}

export interface AdminExtremeEventCreate {
  translations: Translation<ExtremeEventTranslationRole>[];
  published: boolean;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  displayFromDate: string;
  displayFromTime: string;
  fileId?: number;
}

export interface AdminEventCreate {
  translations: Translation<EventTranslationRole>[];
  tagIds: number[];
  personaKeys: PersonaType[];
  published: boolean;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  location: string;
  joinLink?: string;
  isEnglish: boolean;
  fileId: number;
}

export enum PersonaType {
  student = 'student',
  employee = 'employee',
}

export interface Persona {
  id: number;
  key: PersonaType;
  createdAtUtc: string;
  updatedAtUtc: string;
  deletedAtUtc?: string;
}

export interface PersonaDetail {
  personaType: PersonaType;
  isActive: boolean;
  validTo?: string;
}

export interface UploadFileResponse {
  id: number;
  uploadUrl: string;
}

export enum FileModuleType {
  feed = 'feed',
  event = 'event',
  extremeEvent = 'extreme_event',
}
