import React from 'react';
import { View, Text, Pressable } from 'react-native';
import { useTranslation } from 'react-i18next';
import { EXPO_PUBLIC_APP_DOMAIN } from '@env';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import LanguageChanger from '../../components/LanguageChanger';
import ThemeChanger from '../../components/ThemeChanger';
import Layout from '../../components/Layout/index';
import { Icon } from '../../components';
import svgIcons from '../../assets';
import { handleLinkPress } from '../../utils';
import { NavigationParamList, ScreenName, TabNavigationScreenProps } from '../../navigation/types';

const SettingsScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.SettingsScreen>
> = () => {
  const { t, i18n } = useTranslation();

  const navigation =
    useNavigation<NavigationProp<NavigationParamList, ScreenName.PersonalizationScreen>>();

  const handlePrivacyPolicyPress = () => {
    handleLinkPress(`${EXPO_PUBLIC_APP_DOMAIN}/privacy/privacy_${i18n.language}.html`);
  };
  const handleFeedbackPress = () => {
    handleLinkPress('https://forms.clickup.com/24417130/f/q94va-13865/UF1ANHWVMM7PIEJNYD');
  };

  return (
    <Layout title={t('section:settings')}>
      <View className="flex-column gap-y-20">
        <View className="md:flex-row md:w-full flex-column w-full">
          <LanguageChanger />
          <ThemeChanger />
        </View>
        <View className="flex-column">
          <View className="md:flex-row md:w-full flex-column w-full">
            <View className="md:flex-1 md:mr-10 gap-y-4 mb-10">
              <Text className="text-sectionSubtitle font-[sans-700] text-neutral-950 dark:text-neutral-50">
                {t('common:privacy')}
              </Text>
              <View className="flex-row items-center">
                <Pressable onPress={handlePrivacyPolicyPress} className="flex-row items-center">
                  <Icon
                    icon={svgIcons.lockIcon}
                    webSize={{ width: 24, height: 24 }}
                    mobileSize={{ width: 24, height: 24 }}
                    classNames="mr-3 bottom-[1px]"
                  />
                  <Text className="text-xs md:text-body font-[sans-700] text-neutral-950 dark:text-neutral-50 underline">
                    {t('common:privacy_policy')}
                  </Text>
                </Pressable>
              </View>
            </View>
          </View>
          <View className="md:flex-row md:w-full flex-column w-full">
            <View className="md:flex-1 md:mr-10 gap-y-4 mb-10">
              <Text className="text-sectionSubtitle font-[sans-700] text-neutral-950 dark:text-neutral-50">
                {t('common:feedback')}
              </Text>
              <View className="flex-row items-center">
                <Pressable onPress={handleFeedbackPress} className="flex-row items-center">
                  <Icon
                    icon={svgIcons.bugIcon}
                    webSize={{ width: 28, height: 28 }}
                    mobileSize={{ width: 28, height: 28 }}
                    classNames="mr-[8px] bottom-[1px]"
                  />
                  <Text className="text-xs md:text-body font-[sans-700] text-neutral-950 dark:text-neutral-50 underline">
                    {t('common:bug_report')}
                  </Text>
                </Pressable>
              </View>
            </View>
          </View>
          <View className="md:flex-row md:w-full flex-column w-full">
            <Pressable
              onPress={() => navigation.navigate(ScreenName.PersonalizationScreen)}
              className="flex-row items-center justify-between"
            >
              <Text className="text-sectionSubtitle font-[sans-700] text-neutral-950 dark:text-neutral-50 mr-3">
                {t('section:personalization')}
              </Text>
              <Icon
                icon={svgIcons.arrowSimpleRightIcon}
                webSize={{ width: 32, height: 32 }}
                mobileSize={{ width: 24, height: 24 }}
                classNames="mr-3"
              />
            </Pressable>
          </View>
        </View>
      </View>
    </Layout>
  );
};

export default SettingsScreen;
