import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import {
  createNavigatorFactory,
  DefaultNavigatorOptions,
  ParamListBase,
  TabActionHelpers,
  TabNavigationState,
  TabRouter,
  TabRouterOptions,
  useNavigationBuilder,
} from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { EventProvider } from 'react-native-outside-press';
import {
  AdminIndexScreen,
  AdminArticleListScreen,
  AdminArticleEditScreen,
  ArticleScreen,
  ArticlesScreen,
  EventsScreen,
  EventScreen,
  FavoritesScreen,
  HomeScreen,
  OnboardingScreen,
  PlatformsScreen,
  ProfileScreen,
  SettingsScreen,
  AdminExtremeEventListScreen,
  AdminExtremeEventEditScreen,
  AdminEventEditScreen,
  AdminEventListScreen,
  ErrorScreen,
  PersonalizationScreen,
} from '../screens';
import { useModules } from '../contexts/Module/ModuleContext';
import { useAuth } from '../contexts/AuthContext/AuthContext';
import { isNative } from '../utils/responsive';
import navigationConfig from './navigationConfig';
import MainMenu from './MainMenu';
import ProfileMenu from './ProfileMenu';
import { ScreenName, TabNavigationEventMap, TabNavigationOptions } from './types';

type Props = DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap
> &
  TabRouterOptions;

const MainTabNavigation = ({ initialRouteName, children, screenOptions }: Props) => {
  const { state, navigation, descriptors, NavigationContent } = useNavigationBuilder<
    TabNavigationState<ParamListBase>,
    TabRouterOptions,
    TabActionHelpers<ParamListBase>,
    TabNavigationOptions,
    TabNavigationEventMap
  >(TabRouter, {
    children,
    screenOptions,
    initialRouteName,
  });

  const menuProps = {
    state,
    navigation,
    descriptors,
  };

  return (
    <NavigationContent>
      <EventProvider>
        <View style={[{ flex: 1 }]}>
          {state.routes.map((route, i) => {
            return (
              <View
                key={route.key}
                style={[StyleSheet.absoluteFill, { display: i === state.index ? 'flex' : 'none' }]}
              >
                {descriptors[route.key].render()}
              </View>
            );
          })}
        </View>
        {!state.routes[state.index].name.includes('onboarding') && <ProfileMenu {...menuProps} />}
        {!state.routes[state.index].name.includes('onboarding') && <MainMenu {...menuProps} />}
      </EventProvider>
    </NavigationContent>
  );
};

const createTabNavigator = createNavigatorFactory<
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap,
  typeof MainTabNavigation
>(MainTabNavigation);

const CustomNavigator = createTabNavigator();

const MainNavigator = () => {
  const { t } = useTranslation();
  const { isModuleActive } = useModules();
  const { me, isAdmin } = useAuth();

  const screenComponents = {
    [ScreenName.HomeScreen]: HomeScreen,
    [ScreenName.ArticleScreen]: ArticleScreen,
    [ScreenName.ArticlesScreen]: ArticlesScreen,
    [ScreenName.ProfileScreen]: ProfileScreen,
    [ScreenName.EventsScreen]: EventsScreen,
    [ScreenName.EventScreen]: EventScreen,
    [ScreenName.PlatformsScreen]: PlatformsScreen,
    [ScreenName.SettingsScreen]: SettingsScreen,
    [ScreenName.FavoritesScreen]: FavoritesScreen,
    [ScreenName.OnboardingScreen]: OnboardingScreen,
    [ScreenName.AdminIndexScreen]: AdminIndexScreen,
    [ScreenName.AdminArticleListScreen]: AdminArticleListScreen,
    [ScreenName.AdminArticleEditScreen]: AdminArticleEditScreen,
    [ScreenName.AdminExtremeEventListScreen]: AdminExtremeEventListScreen,
    [ScreenName.AdminExtremeEventEditScreen]: AdminExtremeEventEditScreen,
    [ScreenName.AdminEventListScreen]: AdminEventListScreen,
    [ScreenName.AdminEventEditScreen]: AdminEventEditScreen,
    [ScreenName.ErrorScreen]: ErrorScreen,
    [ScreenName.NotFound]: ErrorScreen,
    [ScreenName.PersonalizationScreen]: PersonalizationScreen,
  };

  return (
    <CustomNavigator.Navigator
      initialRouteName={me?.isOnboarded ? 'HomeScreen' : 'OnboardingScreen'}
    >
      {me?.isOnboarded ? (
        navigationConfig.map((screen) => {
          let isActive = true;

          // Check if module is activated
          if (screen?.moduleKey && !isModuleActive(screen.moduleKey)) {
            isActive = false;
          }

          // TODO: itt miért csak az AdminIndex van?
          // Check if user is admin
          if (screen.name === ScreenName.AdminIndexScreen && (!isAdmin || isNative)) {
            isActive = false;
          }

          if (isActive) {
            return (
              <CustomNavigator.Screen
                key={screen.name}
                name={screen.name}
                component={screenComponents[screen.name]}
                options={{
                  title: t(screen.translation),
                }}
              />
            );
          }
        })
      ) : !me ? (
        <CustomNavigator.Screen
          key={ScreenName.ErrorScreen}
          name={ScreenName.ErrorScreen}
          component={ErrorScreen}
        />
      ) : (
        <CustomNavigator.Screen
          key={ScreenName.OnboardingScreen}
          name={ScreenName.OnboardingScreen}
          component={OnboardingScreen}
          options={{
            title: t('navigation:onboarding'),
          }}
        />
      )}
    </CustomNavigator.Navigator>
  );
};
export default MainNavigator;
